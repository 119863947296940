import { RequestData, fetchApi } from "./openapi";

const verify = ({ url }: RequestData<"/proxies/verify", "post">) => {
  const result = fetchApi("/proxies/verify", "post", {
    data: { url },
  });

  return result;
};

export const ProxyService = Object.freeze({
  verify,
});
