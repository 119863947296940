import { Chance } from "chance";
import { pick, range } from "lodash";
import { DateTime } from "luxon";

import {
  CampaignResult,
  ContactResult,
  ConversationResult,
  LeadResult,
  LinkedInConnectionResult,
  LinkedInProfileResult,
  NotificationResult,
  PaymentMethodResult,
  PredefinedMessageTemplateResult,
  ReportResult,
  ScheduledMessageResult,
  TypeaheadElementResult,
} from "@/services/openapi/outbound";
import { RichText } from "@/utils";

import { createId } from "./common-utils";

class AppChance extends Chance {
  id = (): number => createId();

  campaign = (): CampaignResult => ({
    id: this.id(),
    type: "default",
    status: this.pickone(["active", "pending", "inactive"]),
    title: this.sentence({ words: 2 }),
    description: this.paragraph({ sentences: 2 }),
    categories: repeat(() => ({
      id: this.id(),
      label: this.word({ length: 4 }),
    })),
    regions: repeat(() => ({
      id: this.id(),
      label: this.word({ length: 4 }),
    })),
    customAudienceList: null,
    sequence: [
      {
        id: this.id(),
        content: RichText.serializeText(RichText.Empty),
        contentHtml: RichText.serializeHtml(RichText.Empty),
        contentJson: RichText.serializeJson(RichText.Empty),
        offset: 0,
      },
    ],
    sendFollowUpAfterInitialMessageReply: false,
    reachAmount: this.integer({ min: 1000, max: 8000 }),
    recurring: false,
    startDate: this.date().toISOString(),
    createdDate: this.date().toISOString(),
  });

  contact = (): ContactResult => ({
    id: this.id(),
    firstName: this.first(),
    lastName: this.last(),
    fullName: this.name(),
    headline: this.sentence({ words: 2 }),
    summary: this.paragraph({ sentences: 2 }),
    industry: this.word(),
    location: this.sentence({ words: 2 }),
    email: this.email(),
    phoneNumber: this.phone(),
    note: null,
    tags: [],
    linkedInProfile: {
      id: this.id(),
      firstName: this.first(),
      lastName: this.last(),
      fullName: this.name(),
      headline: this.sentence({ words: 2 }),
      summary: this.paragraph({ sentences: 2 }),
      industry: this.word(),
      location: this.sentence({ words: 2 }),
      flagshipProfileUrl: this.url(),
      pictures: [
        {
          url: this.url(),
          width: 800,
          height: 800,
        },
      ],
      createdDate: this.date().toISOString(),
    },
    createdDate: this.date().toISOString(),
  });

  lead = (): LeadResult => ({
    id: this.id(),
    campaign: pick(this.campaign(), "id", "title", "description"),
    contact: this.contact(),
    conversation: {
      id: this.id(),
      cursor: this.guid(),
      isRead: true,
      lastMessage: this.sentence({ words: 4 }),
      lastMessageDate: this.date().toISOString(),
      lastMessageIsOurs: false,
    },
    note: null,
    createdDate: this.date().toISOString(),
  });

  profiles = (): LinkedInProfileResult => ({
    id: this.id(),
    firstName: this.first(),
    lastName: this.last(),
    fullName: this.name(),
    headline: this.sentence({ words: 2 }),
    summary: this.paragraph({ sentences: 2 }),
    industry: this.word(),
    location: this.sentence({ words: 2 }),
    flagshipProfileUrl: this.url(),
    pictures: [
      {
        url: this.url(),
        width: 800,
        height: 800,
      },
    ],
    createdDate: this.date().toISOString(),
  });

  conversation = (): ConversationResult => ({
    id: this.id(),
    campaignId: this.id(),
    recipient: {
      id: this.id(),
      firstName: this.first(),
      lastName: this.last(),
      fullName: this.name(),
      flagshipProfileUrl: this.url(),
    },
    sender: {
      id: this.id(),
      firstName: this.first(),
      lastName: this.last(),
      fullName: this.name(),
      image: undefined,
      flagshipProfileUrl: this.url(),
    },
    lastMessage: {
      id: this.id(),
      text: this.sentence({ words: 3 }),
      sentDate: this.date().toISOString(),
      isOurs: false,
      readDate: null,
    },
    isRead: true,
    isReadOnly: false,
    numberOfSentFollowUps: 0,
    pendingFollowUp: false,
    unreadCount: 0,
  });

  scheduledMessage = (): ScheduledMessageResult => ({
    id: this.id(),
    text: this.paragraph({ sentences: 6 }),
    status: "scheduled",
    scheduledDate: this.date().toISOString(),
  });

  notification = (): NotificationResult => ({
    id: this.id(),
    payload: JSON.stringify({
      id: this.id(),
      title: this.sentence({ words: 2 }),
    }),
    type: "campaignCreated",
    state: "read",
    createdDate: this.date().toISOString(),
  });

  typeahead = (): TypeaheadElementResult => ({
    id: this.id(),
    label: this.string({ length: 6 }),
  });

  paymentMethod = (): PaymentMethodResult => ({
    stripeId: this.guid(),
    card: {
      brand: this.pickone(["visa"]),
      fingerprint: this.guid(),
      expirationMonth: this.integer({ min: 1, max: 12 }),
      expirationYear: this.integer({ min: 2100, max: 2100 }),
      lastFourDigits: this.string({ length: 4, pool: "4" }),
    },
  });

  predefinedMessageTemplate = (): PredefinedMessageTemplateResult => ({
    id: this.id(),
    content: RichText.serializeText(RichText.Empty),
    contentHtml: RichText.serializeHtml(RichText.Empty),
    contentJson: RichText.serializeJson(RichText.Empty),
    isDefault: true,
  });

  report = (): ReportResult => ({
    date: this.date({
      min: DateTime.local().minus({ months: 3 }).toJSDate(),
      max: DateTime.local().toJSDate(),
      string: true,
    }).toString(),
    count: this.integer({ min: 0, max: 1000 }),
  });

  reports = (): ReportResult[] => [
    {
      date: DateTime.local().minus({ days: 30 }).toISO(),
      count: 0,
    },
    {
      date: DateTime.local().minus({ days: 15 }).toISO(),
      count: 0,
    },
    {
      date: DateTime.local().toISO(),
      count: 0,
    },
  ];

  linkedInConnection = (): LinkedInConnectionResult => ({
    id: gen.id(),
    emailAddress: gen.email(),
    password: gen.string({ length: 10 }),
    proxy: "",
    status: "connecting",
  });
}

export const gen = new AppChance();

export const repeat = <T>(genObject: () => T, times = 2) =>
  range(0, times).map(() => genObject());
