import { Navigate, Route, Routes } from "react-router-dom";

import { PageLayout } from "./components/layout";
import { ProtectedRoute } from "./router";
import {
  AccountScreen,
  CallbackScreen,
  CampaignDetailScreen,
  CampaignScreen,
  ConnectionListScreen,
  ContactDetailScreen,
  ContactListScreen,
  ConversationListScreen,
  CreateCampaignScreen,
  IntegrationScreen,
  NotFoundScreen,
} from "./screens";

export const ApplicationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="campaigns" replace />} />

      <Route path="authentication">
        <Route path="callback" element={<CallbackScreen />} />
      </Route>

      <Route path="account" element={<ProtectedRoute />}>
        <Route
          element={
            <PageLayout
              variant="simple"
              defaultRefererUri="/campaigns"
              defaultRefererDisplayName="Campaigns"
            />
          }
        >
          <Route index element={<AccountScreen />} />
          <Route path="connections" element={<ConnectionListScreen />} />
          <Route path="integrations" element={<IntegrationScreen />} />
        </Route>
      </Route>

      <Route path="campaigns" element={<ProtectedRoute />}>
        <Route element={<PageLayout />}>
          <Route index element={<CampaignScreen />} />
        </Route>

        <Route
          element={
            <PageLayout
              variant="simple"
              defaultRefererUri="/campaigns"
              defaultRefererDisplayName="Campaigns"
            />
          }
        >
          <Route path=":id" element={<CampaignDetailScreen />} />
          <Route path="create/*" element={<CreateCampaignScreen />} />
        </Route>
      </Route>

      <Route path="contacts" element={<ProtectedRoute />}>
        <Route element={<PageLayout />}>
          <Route index element={<ContactListScreen />} />
        </Route>

        <Route
          element={
            <PageLayout
              variant="simple"
              defaultRefererUri="/contacts"
              defaultRefererDisplayName="Contacts"
            />
          }
        >
          <Route path=":id" element={<ContactDetailScreen />} />
        </Route>
      </Route>

      <Route path="conversations" element={<ProtectedRoute />}>
        <Route element={<PageLayout />}>
          <Route index element={<ConversationListScreen />} />
        </Route>
      </Route>

      <Route path="*" element={<PageLayout />}>
        <Route path="*" element={<NotFoundScreen />} />
      </Route>
    </Routes>
  );
};
