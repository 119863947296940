import { AsyncReturnType } from "type-fest";

import { ConnectionService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryArgs = Parameters<typeof ConnectionService.getLinkedInAccount>[0];
type QueryFnData = AsyncReturnType<typeof ConnectionService.getLinkedInAccount>;
type Error = unknown;

const queryKey = ({ id }: QueryArgs) => ["connections", id, "account"];

export const useLinkedInAccount = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConnectionService.getLinkedInAccount(args),
    {
      ...options,
    }
  );

  return response;
};

useLinkedInAccount.queryKey = queryKey;
