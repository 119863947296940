import { useFlags } from "flagsmith/react";
import { isNil } from "lodash";

export type Feature = "campaigns" | "connections";

export const useFeature = (feature?: Feature) => {
  const flags = useFlags([feature ?? ""]);

  if (isNil(feature) || isNil(flags)) {
    return true;
  }

  const featureFlag = flags[feature];

  if (isNil(featureFlag)) {
    return true;
  }

  return featureFlag.enabled;
};
