import { forwardRef } from "react";

import {
  PinInput as ChakraPinInput,
  PinInputField as ChakraPinInputField,
  PinInputProps as ChakraPinInputProps,
} from "@chakra-ui/react";
import { range } from "lodash";

import { ControlledFieldProps } from "@/components/form";
import { withController } from "@/components/form/hocs";

export interface PinInputProps
  extends Omit<ChakraPinInputProps, keyof ControlledFieldProps | "children">,
    ControlledFieldProps<
      ChakraPinInputProps["value"],
      (value: string) => void
    > {
  length: number;
}

export const PinInput = forwardRef<HTMLInputElement, PinInputProps>(
  ({ value, length, onChange, ...props }: PinInputProps) => {
    return (
      <ChakraPinInput
        value={value}
        onChange={(value) => {
          onChange?.(value);
        }}
        {...props}
      >
        {range(0, length).map((_, index) => (
          <ChakraPinInputField key={index} />
        ))}
      </ChakraPinInput>
    );
  }
);

PinInput.displayName = "PinInput";

export const PinInputFormField = withController(PinInput);
