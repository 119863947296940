import { ReactNode, useCallback, useMemo } from "react";

import {
  Box,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  VStack,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { FormProvider } from "react-hook-form";
import { z } from "zod";

import { Modal, ModalProps } from "@/components/disclosure";
import { Text } from "@/components/display";
import {
  Button,
  FormControl,
  Option,
  PinInput,
  Select,
} from "@/components/form";
import { useFormState } from "@/hooks";
import { useQueryClient } from "@/services/hooks";
import { useCampaigns } from "@/services/hooks/campaigns";
import {
  useAssignAccount,
  useLinkedInConnection,
  useLinkedInConnections,
  useUpdateLinkedInConnection,
  useVerificationCode,
  useVerifyLinkedInAccount,
} from "@/services/hooks/connections";
import { useLinkedInAccount } from "@/services/hooks/connections/use-linkedin-account";
import { LinkedInConnection } from "@/services/types";
import { createContext } from "@/ui/utils";

import {
  LinkAccountFieldValues,
  LinkAccountForm,
  useLinkAccountForm,
} from "./link-account-form";

interface ConnectionDetailModalContextProps {
  connection?: LinkedInConnection;
}

const [ConnectionDetailModalProvider, useConnectionDetailModalContext] =
  createContext<ConnectionDetailModalContextProps>({
    name: "ConnectionDetailModalContext",
    defaultValue: {},
  });

export interface ConnectionDetailModalProps
  extends Omit<ModalProps, "children" | "size"> {
  connection?: LinkedInConnection;
}

export const ConnectionDetailModal = ({
  connection: outerConnection,
  isOpen,
  onClose,
}: ConnectionDetailModalProps) => {
  const connectionId = outerConnection?.id;

  const { data: connection } = useLinkedInConnection(
    { id: connectionId! },
    {
      enabled: !isNil(connectionId),
      placeholderData: outerConnection,
      keepPreviousData: isNil(outerConnection),
      refetchInterval: 10000,
    }
  );

  const contentByStatus: Record<LinkedInConnection["status"], ReactNode> = {
    connectionRequested: <ConnectingContent />,
    connecting: <ConnectingContent />,
    connected: <ConnectedContent />,
    twoFactorRequiredApp: <TwoFactorRequiredContent />,
    twoFactorRequiredEmail: <TwoFactorRequiredContent />,
    connectionRequestExpired: <LinkAccountContent />,
    invalidCredentials: <LinkAccountContent />,
    connectionCanceled: <LinkAccountContent />,
    connectionFailed: <LinkAccountContent />,
    accountLocked: <AccountBlockedContent />,
    accountBanned: <AccountBlockedContent />,
  };

  return (
    <ConnectionDetailModalProvider value={{ connection }}>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />

        {connection && contentByStatus[connection.status]}
      </Modal>
    </ConnectionDetailModalProvider>
  );
};

const ConnectingContent = () => {
  const { connection } = useConnectionDetailModalContext();

  const queryClient = useQueryClient();

  const { setInterimData: setInterimLinkedInConnectionData } =
    useLinkedInConnection({
      id: connection!.id,
    });

  const {
    mutateAsync: updateLinkedInConnection,
    isLoading: isUpdatingLinkedInConnection,
  } = useUpdateLinkedInConnection({
    onMutate: ({ data: { emailAddress, password, proxy, status } }) => {
      return setInterimLinkedInConnectionData(
        (draft) => {
          if (!draft) return;

          draft.emailAddress = emailAddress ?? draft.emailAddress;
          draft.password = password ?? draft.password;
          draft.proxy = proxy ?? draft.proxy;
          draft.status = status ?? draft.status;
        },
        { shouldPause: true }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(useLinkedInConnections.queryKey());
    },
  });

  const handleCancelLinkAccount = useCallback(async () => {
    await updateLinkedInConnection({
      id: connection!.id,
      data: {
        emailAddress: connection!.emailAddress,
        password: connection!.password,
        status: "connectionCanceled",
        proxy: connection!.proxy,
      },
    });
  }, [connection, updateLinkedInConnection]);

  return (
    <ModalContent>
      <ModalHeader>Linking account...</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <VStack alignItems="flex-start">
          <Spinner mx="auto" mb={4} />

          <Text>
            We are linking your account{" "}
            <strong>{connection?.emailAddress}</strong> and setting everything
            up. This process may take a while.
          </Text>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button
          isLoading={isUpdatingLinkedInConnection}
          loadingText="Canceling"
          onClick={handleCancelLinkAccount}
        >
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const ConnectedContent = () => {
  const { connection } = useConnectionDetailModalContext();

  const queryClient = useQueryClient();

  const { data: campaigns = [], isLoading: isLoadingCampaigns } = useCampaigns(
    undefined,
    {
      select: (x) =>
        x.elements.map(
          (y) =>
            ({
              value: y.id.toString(),
              label: y.title,
            } as Option)
        ),
    }
  );

  const {
    data: account,
    isLoading: isLoadingAccount,
    setInterimData: setInterimAccount,
  } = useLinkedInAccount({
    id: connection!.id,
  });

  const { setInterimData: setInterimLinkedInConnectionData } =
    useLinkedInConnection({
      id: connection!.id,
    });

  const {
    mutateAsync: updateLinkedInConnection,
    isLoading: isUpdatingLinkedInConnection,
  } = useUpdateLinkedInConnection({
    onMutate: ({ data: { emailAddress, password, proxy, status } }) => {
      return setInterimLinkedInConnectionData(
        (draft) => {
          if (!draft) return;

          draft.emailAddress = emailAddress ?? draft.emailAddress;
          draft.password = password ?? draft.password;
          draft.proxy = proxy ?? draft.proxy;
          draft.status = status ?? draft.status;
        },
        { shouldPause: true }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(useLinkedInConnections.queryKey());
    },
  });

  const { mutateAsync: assignAccount } = useAssignAccount({
    onMutate: ({ campaign }) => {
      return setInterimAccount((draft) => {
        if (!draft) return;

        draft.campaignId = campaign.id ?? null;
      });
    },
  });

  const selectedCampaign = useMemo(
    () => campaigns.find((x) => x.value === account?.campaignId?.toString()),
    [campaigns, account]
  );

  // TODO: Delete instead?
  const handleUnlinkAccount = useCallback(async () => {
    await updateLinkedInConnection({
      id: connection!.id,
      data: {
        emailAddress: connection!.emailAddress,
        password: connection!.password,
        status: "connectionCanceled",
        proxy: connection!.proxy,
      },
    });
  }, [connection, updateLinkedInConnection]);

  const handleAssignAccount = useCallback(
    async (campaignId: number | null) => {
      await assignAccount({
        id: connection!.id,
        campaign: {
          id: campaignId,
        },
      });
    },
    [assignAccount, connection]
  );

  return (
    <ModalContent>
      <ModalHeader>Account info</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <VStack alignItems="flex-start" spacing={4}>
          <Text>
            Your account <strong>{connection?.emailAddress}</strong> has been
            linked successfully.
          </Text>

          <Box w="full">
            <Select
              isClearable
              menuPosition="fixed"
              placeholder="Assign to campaign..."
              value={selectedCampaign}
              isLoading={isLoadingCampaigns || isLoadingAccount}
              isDisabled={
                isLoadingCampaigns || isLoadingAccount || !account?.profileId
              }
              options={campaigns}
              onChange={(option) =>
                void handleAssignAccount(
                  isNil(option?.value) ? null : parseInt(option.value)
                )
              }
            />
          </Box>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="red"
          variant="outline"
          isLoading={isUpdatingLinkedInConnection}
          loadingText="Unlinking account"
          onClick={handleUnlinkAccount}
        >
          Unlink Account
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const TwoFactorRequiredContent = () => {
  const { connection } = useConnectionDetailModalContext();

  const {
    state: modalState,
    updateState: updateModalState,
    isValid: isModalStateValid,
  } = useFormState({
    defaultValue: {
      verificationCode: "",
    },
    schema: z.object({
      verificationCode: z
        .string()
        .length(6, "The value must 6 characters long"),
    }),
  });

  const {
    data: { verificationCode } = {},
    isLoading: isLoadingVerificationCode,
    setInterimData: setInterimVerificationCode,
  } = useVerificationCode(
    {
      id: connection!.id,
    },
    {
      refetchInterval: 60000,
      onDataChange: (data) => {
        updateModalState({ verificationCode: data?.verificationCode ?? "" });
      },
    }
  );

  const { mutateAsync: verifyLinkedInAccount } = useVerifyLinkedInAccount({
    onMutate: ({ data: { verificationCode } }) => {
      return setInterimVerificationCode((draft) => {
        if (!draft) return;

        draft.verificationCode = verificationCode;
      });
    },
  });

  const handleVerifyLinkedInAccount = useCallback(async () => {
    await verifyLinkedInAccount({
      id: connection!.id,
      data: { verificationCode: modalState.verificationCode },
    });
  }, [verifyLinkedInAccount, connection, modalState.verificationCode]);

  return (
    <ModalContent>
      <ModalHeader>Confirm your sign-in</ModalHeader>
      <ModalCloseButton />

      <ModalBody
        pb={connection?.status === "twoFactorRequiredApp" ? 6 : undefined}
      >
        {connection?.status === "twoFactorRequiredEmail" && (
          <VStack alignItems="flex-start" gap={4}>
            <Text>
              A confirmation email has been sent to{" "}
              <strong>{connection?.emailAddress}</strong> with a code to verify
              your identity.
            </Text>

            <Text>
              Please enter the code that was sent to your email address to
              continue.
            </Text>

            <FormControl>
              <HStack>
                <PinInput
                  length={6}
                  value={modalState.verificationCode}
                  isDisabled={
                    isLoadingVerificationCode || !isNil(verificationCode)
                  }
                  onChange={(value) => {
                    updateModalState({ verificationCode: value });
                  }}
                />
              </HStack>
            </FormControl>
          </VStack>
        )}

        {connection?.status === "twoFactorRequiredApp" && (
          <VStack alignItems="flex-start">
            <Spinner mx="auto" mb={4} />

            <Text>
              Please open your LinkedIn app and tap <strong>Yes</strong> to
              confirm your sign-in.
            </Text>
          </VStack>
        )}
      </ModalBody>

      {connection?.status === "twoFactorRequiredEmail" && (
        <ModalFooter>
          <Button
            colorScheme="primary"
            isDisabled={!isModalStateValid || isLoadingVerificationCode}
            isLoading={!isNil(verificationCode)}
            loadingText="Linking account"
            onClick={handleVerifyLinkedInAccount}
          >
            Link Account
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  );
};

const LinkAccountContent = () => {
  const { connection } = useConnectionDetailModalContext();

  const queryClient = useQueryClient();

  const { setInterimData: setInterimLinkedInConnectionData } =
    useLinkedInConnection({
      id: connection!.id,
    });

  const { mutateAsync: updateLinkedInConnection } = useUpdateLinkedInConnection(
    {
      onMutate: ({ data: { emailAddress, password, proxy } }) => {
        return setInterimLinkedInConnectionData(
          (draft) => {
            if (!draft) return;

            draft.emailAddress = emailAddress ?? draft.emailAddress;
            draft.password = password ?? draft.password;
            draft.proxy = proxy ?? draft.proxy;
            draft.status = "connectionRequested";
          },
          { shouldPause: true }
        );
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(useLinkedInConnections.queryKey());
      },
    }
  );

  const methods = useLinkAccountForm({
    defaultValues: {
      emailAddress: connection?.emailAddress ?? "",
      password: connection?.password ?? "",
      proxy: connection?.proxy ?? "",
    },
  });

  const {
    formState: { isSubmitting, isValidating, isValid },
  } = methods;

  const handleSubmit = useCallback(
    async (data: LinkAccountFieldValues) => {
      await updateLinkedInConnection({
        id: connection!.id,
        data: {
          emailAddress: data.emailAddress,
          password: data.password,
          proxy: data.proxy,
        },
      });
    },
    [connection, updateLinkedInConnection]
  );

  return (
    <ModalContent>
      <ModalHeader>Link account</ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <FormProvider {...methods}>
          <VStack alignItems="stretch" spacing={4}>
            {connection?.status === "invalidCredentials" && (
              <Text color="red.500" fontSize="sm">
                We could not link your account because the credentials you
                entered are invalid. Please try again.
              </Text>
            )}

            {connection?.status === "connectionFailed" && (
              <Text color="red.500" fontSize="sm">
                We could not link your account. Please try again.
              </Text>
            )}

            {connection?.status === "connectionRequestExpired" && (
              <Text color="yellow.500" fontSize="sm">
                Your connection request has expired. Please try linking your
                account again.
              </Text>
            )}

            <LinkAccountForm
              id="connection-detail-modal__form"
              onSubmit={methods.handleSubmit(handleSubmit)}
            />
          </VStack>
        </FormProvider>
      </ModalBody>

      <ModalFooter>
        <Button
          type="submit"
          form="connection-detail-modal__form"
          colorScheme="primary"
          isDisabled={!isValid || isValidating}
          isLoading={isSubmitting}
          loadingText="Linking account"
        >
          Link Account
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const AccountBlockedContent = () => {
  const { connection } = useConnectionDetailModalContext();

  const queryClient = useQueryClient();

  const { setInterimData: setInterimLinkedInConnectionData } =
    useLinkedInConnection({
      id: connection!.id,
    });

  const {
    mutateAsync: updateLinkedInConnection,
    isLoading: isUpdatingLinkedInConnection,
  } = useUpdateLinkedInConnection({
    onMutate: ({ data: { emailAddress, password, proxy, status } }) => {
      return setInterimLinkedInConnectionData(
        (draft) => {
          if (!draft) return;

          draft.emailAddress = emailAddress ?? draft.emailAddress;
          draft.password = password ?? draft.password;
          draft.proxy = proxy ?? draft.proxy;
          draft.status = status ?? draft.status;
        },
        { shouldPause: true }
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(useLinkedInConnections.queryKey());
    },
  });

  const handleUnlinkAccount = useCallback(async () => {
    await updateLinkedInConnection({
      id: connection!.id,
      data: {
        emailAddress: connection!.emailAddress,
        password: connection!.password,
        status: "connectionCanceled",
        proxy: connection!.proxy,
      },
    });
  }, [connection, updateLinkedInConnection]);

  return (
    <ModalContent>
      <ModalHeader>
        {connection?.status === "accountLocked" && "Account locked"}
        {connection?.status === "accountBanned" && "Account banned"}
      </ModalHeader>
      <ModalCloseButton />

      <ModalBody>
        <VStack alignItems="flex-start">
          <Text>
            This account <strong>{connection?.emailAddress}</strong> has been{" "}
            {connection?.status === "accountLocked" && "locked"}
            {connection?.status === "accountBanned" && "banned"}.
          </Text>
        </VStack>
      </ModalBody>

      <ModalFooter>
        <Button
          colorScheme="red"
          variant="outline"
          isLoading={isUpdatingLinkedInConnection}
          loadingText="Unlinking account"
          onClick={handleUnlinkAccount}
        >
          Unlink Account
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
