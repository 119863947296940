import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { ConnectionService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen, repeat } from "@/utils";

type QueryFnData = AsyncReturnType<
  typeof ConnectionService.getLinkedInConnections
>;
type Error = unknown;

const queryKey = () => ["connections"];

export const useLinkedInConnections = <TData = QueryFnData>(
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(),
    () => ConnectionService.getLinkedInConnections(),
    {
      skeletonData: useConst({
        elements: repeat(gen.linkedInConnection, 3),
      }),
      ...options,
    }
  );

  return response;
};

useLinkedInConnections.queryKey = queryKey;
