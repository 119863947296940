import { useConst } from "@chakra-ui/react";
import { AsyncReturnType } from "type-fest";

import { ConnectionService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";
import { gen } from "@/utils";

type QueryArgs = Parameters<typeof ConnectionService.getLinkedInConnection>[0];
type QueryFnData = AsyncReturnType<
  typeof ConnectionService.getLinkedInConnection
>;
type Error = unknown;

const queryKey = ({ id }: QueryArgs) => ["connections", id];

export const useLinkedInConnection = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConnectionService.getLinkedInConnection(args),
    {
      skeletonData: useConst(gen.linkedInConnection()),
      ...options,
    }
  );

  return response;
};

useLinkedInConnection.queryKey = queryKey;
