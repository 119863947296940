import { FormControl, FormControlProps } from "@chakra-ui/react";
import { isArray, isEmpty, isNil, isObject, uniqBy } from "lodash";
import { FieldError, FieldErrors, useFormContext } from "react-hook-form";

import { QuerySuspense } from "@/components/disclosure";

import { FormError } from "./form-error";
import { FormFieldControlProvider } from "./form-field-control-context";

export interface FormFieldControlProps extends FormControlProps {
  name: string;
  isLoading?: boolean;
}

export const FormFieldControl = ({
  name,
  isLoading = false,
  isDisabled,
  children,
  ...props
}: FormFieldControlProps) => {
  const {
    formState: { errors },
  } = useFormContext();

  const errorMessages = flattenErrors(errors[name]);
  const isInvalid = !isEmpty(errorMessages);

  return (
    <FormFieldControlProvider value={{ name }}>
      <QuerySuspense suspended={isLoading}>
        <FormControl isInvalid={isInvalid} isDisabled={isDisabled} {...props}>
          {children}

          <FormError
            errors={errorMessages.map((x) => x?.message?.toString())}
          />
        </FormControl>
      </QuerySuspense>
    </FormFieldControlProvider>
  );
};

const flattenErrors = (
  errors?: FieldErrors[keyof FieldErrors]
): FieldError[] => {
  if (isNil(errors) || isEmpty(errors)) {
    return [];
  }

  const result: FieldError[] = [];

  const traverse = (innerErrors: typeof errors) => {
    if (isArray(innerErrors)) {
      innerErrors.forEach(traverse);
    } else if (isObject(innerErrors)) {
      for (const key in innerErrors) {
        if (Object.hasOwn(innerErrors, key)) {
          if (key === "message") {
            result.push(innerErrors as unknown as FieldError);
          } else {
            // @ts-ignore - We know that we can index innerErrors
            traverse(innerErrors[key]!);
          }
        }
      }
    }
  };

  traverse(errors);

  return uniqBy(result, "message");
};
