import { AsyncReturnType } from "type-fest";

import { ConnectionService } from "@/services";
import { UseQuerySimpleOptions, useQuery } from "@/services/hooks";

type QueryArgs = Parameters<typeof ConnectionService.getVerificationCode>[0];
type QueryFnData = AsyncReturnType<
  typeof ConnectionService.getVerificationCode
>;
type Error = unknown;

const queryKey = ({ id }: QueryArgs) => ["connections", id, "verification"];

export const useVerificationCode = <TData = QueryFnData>(
  args: QueryArgs,
  options?: UseQuerySimpleOptions<QueryFnData, Error, TData>
) => {
  const response = useQuery<QueryFnData, Error, TData>(
    queryKey(args),
    () => ConnectionService.getVerificationCode(args),
    options
  );

  return response;
};

useVerificationCode.queryKey = queryKey;
