import { useCallback } from "react";

import {
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { FormProvider } from "react-hook-form";

import { MaybePromise } from "@/common";
import { Modal, ModalProps } from "@/components/disclosure";
import { Button } from "@/components/form";

import {
  LinkAccountFieldValues,
  LinkAccountForm,
  useLinkAccountForm,
} from "./link-account-form";

export interface LinkAccountModalProps
  extends Omit<ModalProps, "children" | "size"> {
  onLink: (account: LinkAccountFieldValues) => MaybePromise<void>;
}

export const LinkAccountModal = ({
  isOpen,
  onClose,
  onLink,
}: LinkAccountModalProps) => {
  const methods = useLinkAccountForm();

  const {
    formState: { isSubmitting, isValid },
    reset,
  } = methods;

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleSubmit = useCallback(
    async (data: LinkAccountFieldValues) => {
      await onLink(data);
      handleClose();
      reset();
    },
    [handleClose, onLink, reset]
  );

  return (
    <Modal size="sm" isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Link account</ModalHeader>
        <ModalCloseButton />

        <ModalBody>
          <FormProvider {...methods}>
            <LinkAccountForm
              id="link-account-modal__form"
              onSubmit={methods.handleSubmit(handleSubmit)}
            />
          </FormProvider>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            form="link-account-modal__form"
            colorScheme="primary"
            isDisabled={!isValid}
            isLoading={isSubmitting}
            loadingText="Linking account"
          >
            Link Account
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
