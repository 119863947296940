import { RequestData, RequestParams, fetchApi } from "./openapi";

const getLinkedInConnections = () => {
  const result = fetchApi("/connections", "get");

  return result;
};

const getLinkedInConnection = ({
  id,
}: RequestParams<"/connections/{id}", "get">) => {
  const result = fetchApi("/connections/{id}", "get", {
    params: { path: { id } },
  });

  return result;
};

const getVerificationCode = ({
  id,
}: RequestParams<"/connections/{id}/verification", "get">) => {
  const result = fetchApi("/connections/{id}/verification", "get", {
    params: { path: { id } },
  });

  return result;
};

const linkLinkedInAccount = (data: RequestData<"/connections", "post">) => {
  const result = fetchApi("/connections", "post", {
    data,
  });

  return result;
};

const verifyLinkedInAccount = ({
  id,
  data,
}: RequestParams<"/connections/{id}/verification", "post"> & {
  data: RequestData<"/connections/{id}/verification", "post">;
}) => {
  const result = fetchApi("/connections/{id}/verification", "post", {
    params: { path: { id } },
    data,
  });

  return result;
};

const updateLinkedInConnection = ({
  id,
  data,
}: RequestParams<"/connections/{id}", "put"> & {
  data: RequestData<"/connections/{id}", "put">;
}) => {
  const result = fetchApi("/connections/{id}", "put", {
    params: { path: { id } },
    data,
  });

  return result;
};

const getLinkedInAccount = ({
  id,
}: RequestParams<"/connections/{id}/account", "get">) => {
  const result = fetchApi("/connections/{id}/account", "get", {
    params: {
      path: {
        id,
      },
    },
  });

  return result;
};

const assignAccount = (
  args: RequestParams<"/connections/{id}/account/assign", "post"> & {
    campaign: RequestData<"/connections/{id}/account/assign", "post">;
  }
) => {
  const result = fetchApi("/connections/{id}/account/assign", "post", {
    params: { path: { id: args.id } },
    data: args.campaign,
  });

  return result;
};

export const ConnectionService = Object.freeze({
  getLinkedInConnections,
  getLinkedInConnection,
  getVerificationCode,
  linkLinkedInAccount,
  verifyLinkedInAccount,
  updateLinkedInConnection,
  getLinkedInAccount,
  assignAccount,
});
