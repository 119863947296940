import { useCallback, useMemo, useState } from "react";

import {
  Box,
  Flex,
  Icon,
  List,
  ListItem,
  Spinner,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { isNil } from "lodash";
import { Helmet } from "react-helmet-async";
import {
  MdCheckCircleOutline,
  MdErrorOutline,
  MdRemoveCircleOutline,
} from "react-icons/md";
import { NumberParam, useQueryParam } from "use-query-params";

import { QuerySuspense } from "@/components/disclosure";
import { Heading, Text } from "@/components/display";
import { Button, ButtonBox, SearchInput } from "@/components/form";
import {
  ConnectionDetailModal,
  LinkAccountModal,
} from "@/partials/connections";
import {
  useLinkLinkedInAccount,
  useLinkedInConnections,
} from "@/services/hooks/connections";
import { ComponentProp } from "@/ui/types";
import { StringUtils, createId } from "@/utils";

export const ConnectionListScreen = () => {
  const [connectionId, setConnectionId] = useQueryParam("id", NumberParam);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    isOpen: isLinkModalOpen,
    onOpen: openLinkModal,
    onClose: closeLinkModal,
  } = useDisclosure();

  const {
    data: { elements: connections } = {},
    status: connectionsStatus,
    setInterimData: setInterimConnections,
  } = useLinkedInConnections({
    refetchInterval: 10000,
    enableSkeletonData: true,
  });

  const filteredConnections = useMemo(
    () =>
      connections?.filter((x) =>
        x.emailAddress.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [connections, searchQuery]
  );

  const selectedConnection = useMemo(
    () => connections?.find((x) => x.id === connectionId),
    [connections, connectionId]
  );

  const { mutateAsync: linkAccount } = useLinkLinkedInAccount({
    onMutate: ({ emailAddress, password, proxy }) => {
      return setInterimConnections((draft) => {
        draft?.elements.push({
          id: createId(),
          emailAddress,
          password,
          proxy,
          status: "connecting",
        });
      });
    },
  });

  const handleLinkAccount = useCallback<
    ComponentProp<typeof LinkAccountModal, "onLink">
  >(
    async (account) => {
      const connection = await linkAccount({
        emailAddress: account.emailAddress,
        password: account.password,
        proxy: account.proxy,
      });

      setConnectionId(connection.id);
    },
    [linkAccount, setConnectionId]
  );

  return (
    <>
      <Helmet>
        <title>Connections - Cosiall</title>
      </Helmet>

      <LinkAccountModal
        isOpen={isLinkModalOpen}
        onClose={closeLinkModal}
        onLink={handleLinkAccount}
      />

      <ConnectionDetailModal
        connection={selectedConnection}
        isOpen={!isNil(selectedConnection)}
        onClose={() => setConnectionId(undefined)}
      />

      <Stack
        w="full"
        direction={{ base: "column", md: "row" }}
        alignItems="center"
        spacing={{ base: 2, lg: 4 }}
        mb={{ base: 2, lg: 4 }}
        p="2px"
      >
        <Heading
          as="h1"
          size="lg"
          display={{ base: "none", md: "unset" }}
          suspendable={false}
        >
          Connections
        </Heading>

        <Flex flex="1" gap={1} w={{ base: "full", lg: "unset" }}>
          <SearchInput
            value={searchQuery}
            size="sm"
            placeholder="Search connections"
            flex="2 1 auto"
            maxW={{ base: "unset", md: "40ch" }}
            onChange={setSearchQuery}
          />

          <Button colorScheme="primary" onClick={openLinkModal}>
            Link Account
          </Button>
        </Flex>
      </Stack>

      <QuerySuspense status={connectionsStatus}>
        <Box as="section" my={4}>
          <Box as="span" display="inline-block" fontSize="sm" color="gray.500">
            <Text as="span" display="inline-block" mr="0.5ch" fontWeight="bold">
              {filteredConnections?.length ?? 0}
            </Text>

            <Text as="span" display="inline-block">
              {StringUtils.pluralize(
                filteredConnections?.length,
                "connection",
                "connections"
              )}
            </Text>
          </Box>
        </Box>
      </QuerySuspense>

      <QuerySuspense status={connectionsStatus}>
        <List spacing={2}>
          {filteredConnections?.map((connection) => (
            <ListItem
              as={ButtonBox}
              key={connection.id}
              w="full"
              p={4}
              borderRadius="md"
              background="gray.50"
              onClick={() => setConnectionId(connection.id)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <Text as="span">{connection.emailAddress}</Text>

                <Box display="flex" alignItems="center">
                  {(connection.status === "connectionRequested" ||
                    connection.status === "connecting") && (
                    <Spinner size="xs" />
                  )}

                  {connection.status === "connected" && (
                    <Icon as={MdCheckCircleOutline} color="green.500" />
                  )}

                  {(connection.status === "connectionFailed" ||
                    connection.status === "invalidCredentials" ||
                    connection.status === "twoFactorRequiredApp" ||
                    connection.status === "twoFactorRequiredEmail" ||
                    connection.status === "connectionRequestExpired" ||
                    connection.status === "accountLocked" ||
                    connection.status === "accountBanned") && (
                    <Icon as={MdErrorOutline} color="yellow.500" />
                  )}

                  {connection.status === "connectionCanceled" && (
                    <Icon as={MdRemoveCircleOutline} />
                  )}
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </QuerySuspense>
    </>
  );
};
